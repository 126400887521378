@import "../../css/fonts/fonts.css";

.card_partners{
    width: 100%;
    height: 280px;
    padding: 30px 0;
    background: #FFF;
    text-align: center;
    position: relative;
    margin-bottom: 25px;
}

.card_partners:hover{
    -webkit-box-shadow: 0px 3px 4px 2px rgba(51,51,51,0.1);
    -moz-box-shadow: 0px 3px 4px 2px rgba(51,51,51,0.1);
    box-shadow: 0px 3px 4px 2px rgba(51,51,51,0.1);

    .logo{
        transform: scale(1.1);
        transition: ease .1s;
    }
    
}

.logo{
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 50px;
}

.container_name{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 110px;
    text-align: center;
    margin: auto;
}

.name_partner{
    font-family: 'LGSmartGlobalRegular';
    font-size: 16px;
    color: #707070;
}

.container_button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 30px;
    text-align: center;
    margin: auto;
}

.button_onlineBuy{
    padding: 10px 20px;
    border: 1px solid #707070;
    border-radius: 3px;
    color: #707070;
    background-color: #fff;
    text-transform: uppercase;
}

a:hover {
    text-decoration: unset !important;
    color: #fff !important;
    background-color: #333333;
    border: unset;
}

@media screen and (max-width: 767px) {
   
  }