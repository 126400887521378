@import "../../css/fonts/fonts.css";

.container_buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
}

.button{
    background-color: #A50034;
    border: unset;
    border-radius: 3px;
    color: #fff;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    font-size: 13.5px;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
   
  }