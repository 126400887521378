@font-face {
    font-family: "LGSmartBoldItalic";
    src: local("LGSmartBoldItalic"),
         local("LGSmartBoldItalic"),
         url('LG-Smart-Bold-Italic.ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: "LGSmartBold";
    src: local("LGSmartBold"),
         local("LGSmartBold"),
         url('LG-Smart-Bold.ttf');
    font-display: swap;
  }

  @font-face {
     font-family: "LGSmartGlobalBold";
     src: local("LGSmartGlobalBold"),
          local("LGSmartGlobalBold"),
          url('LG-Smart-Global-Bold.ttf');
     font-display: swap;
   }
  
  @font-face {
    font-family: "LGSmartGlobalLightLT";
    src: local("LGSmartGlobalLightLT"),
         local("LGSmartGlobalLightLT"),
         url('LG-Smart-Global-Light-LT.ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: "LgSmartGlobalLightSemibold";
    src: local("LgSmartGlobalLightSemibold"),
         local("LgSmartGlobalLightSemibold"),
         url('LG-Smart-Global-Light-SemiBold.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: "LgSmartGlobalRegular";
    src: local("LgSmartGlobalRegular"),
         local("LgSmartGlobalRegular"),
         url('LG-Smart-Global-Regular.ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: "LgSmartLightLT";
    src: local("LgSmartLightLT"),
         local("LgSmartLightLT"),
         url('LG-Smart-Light-LT.ttf');
    font-display: swap;
  }

  @font-face {
     font-family: "LgSmartLightSemibold";
     src: local("LgSmartLightSemibold"),
          local("LgSmartLightSemibold"),
          url('LG-Smart-Light-SemiBold.ttf');
     font-display: swap;
   }

   @font-face {
     font-family: "LgSmartRegularItalic";
     src: local("LgSmartRegularItalic"),
          local("LgSmartRegularItalic"),
          url('LG-Smart-Regular-Italic.ttf');
     font-display: swap;
   }

   @font-face {
     font-family: "LgSmartRegular";
     src: local("LgSmartRegular"),
          local("LgSmartRegular"),
          url('LG-Smart-Regular.ttf');
     font-display: swap;
   }
  
  