@import "../../css/fonts/fonts.css";



.header{
    /* display: flex;
    width: 100%;
    height: 70px; */
    background-color: white;
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */

}
.logo{
    min-width: 40px;
    /* height: 60px; */
}

/* .maxs{
    display: flex;
    max-width: 1280px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    padding-right: 20px;
} */
hr {
    border: 0;
    clear:both;
    display:block;
    width: 100%;              
    /* background-color:#e5e5e5; */
    height: 1px;
   /*  max-width: 1280px; */
   margin-bottom: 0 !important;
}

.saibamais{
    font-family: 'LGSmartGlobalRegular', Arial, Helvetica, sans-serif;
    color: #494239;
    font-size: 16px;
    text-align: right;
    margin: 0;

    a{
        color: #494239 !important;
        text-decoration: unset !important;
        background-color: unset !important;
    }

    a:hover{
        color: #494239 !important;
        text-decoration: unset !important;
        background-color: unset !important;
    }
}




@media screen and (max-width: 767px) {
    .logo{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .saibamais{
        text-align: center;

    }
 
  }





  .contConMOb{
      display: flex;
      flex-direction: row;
      width: 90%;
      height: 60px;
      align-items: center;
      justify-content: space-between;
  }

.rowExitView2{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    width: 100%;
    height: 10%;
    min-height: 20px;
    span{
        color: white;
        font-size: 22px;
        cursor: pointer;
    }
}

.sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    background-size: cover;
    background-color:#A50034 ;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
  }