@import "../../css/fonts/fonts.css";

.noScroll{
    max-height: calc(100vh - 300px);
    overflow: hidden;
}
.container{
    display: flex;
    /* align-items: center;
    flex-direction: column; */
    justify-content: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    /*min-height: 100vh; */
   /*  overflow: hidden; */
    padding: 30px 35px;
    align-items: center;
}

.no_padding_top{
    padding-top: 0;
    display: flex;
    /* align-items: center;
    flex-direction: column; */
    justify-content: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    /*min-height: 100vh; */
   /*  overflow: hidden; */
    padding-left: 35px;
    padding-right: 35px;
    align-items: center;
}

.body_view{
    //height: 53vh;
    width: 90%;
    overflow: hidden;
    overflow-y: hidden;
}

.view{
    /* display: flex; */
    align-items: flex-start;
    justify-content: center;
    
    width: 100%;
    /* padding-left: 20px;
    padding-right: 20px; */
    /* overflow-y: scroll; */
    /* min-height: 40px; */
    margin: 15px 0;
    
}
/* .padding{
    padding: 10px;
} */

hr {
    border: 0;
    clear:both;
    display:block;
    width: 100%;               
    background-color:#e5e5e5;
    height: 1px;
    margin-bottom: 0 !important;
}

.hr_simulator {
    border: 0px;
    display:block;
    width: 100%;               
    background-color:#e5e5e5;
    height: 1px;
    clear:both;
}


.rowwEnd{
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    /* flex-direction: row-reverse; */
}

.textSimulator{
    font-family: 'LGSmartLightSemiBold', Arial, Helvetica, sans-serif;
    color: #000000;
    font-size: 38px;
}
.text{
    font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
    color: #333333;
    padding-top: 7px;
    padding-bottom: 20px;
    font-size: 18px;
}
.textS{
    color: #333333;
    font-size: 18px;
    margin: 0;
}
.rows{
    display: flex;
    flex-direction: row;
    padding: 4px;
    align-items: center;
    justify-content: center;

    /* select{
        font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
        width: 79px;
        height: 24px;
        option{
            width: 79px;
            height: 24px;
            background: white 0% 0% no-repeat padding-box;
            opacity: 1;     
        }
    } */

    
        button, .show>.btn-primary.dropdown-toggle, .show>.btn-primary.dropdown-toggle:focus, .btn-primary.focus, .btn-primary:focus{
            background-color: #fff !important;
            color: #000000 !important;
            border-color: #707070 !important;
            border-radius: 2px !important;
            height: 28px !important;
            display: flex !important;
            align-items: center !important;
            box-shadow: unset !important;
        }
    
        button:hover{
            background-color: #A50034 !important;
            color: #fff !important;
            border-color: #A50034 !important;
            border-radius: 2px !important;
            height: 28px !important;
            display: flex !important;
            align-items: center !important;
            box-shadow: unset !important;
        }
    
        .show>.btn-primary.dropdown-toggle:focus {
            box-shadow: unset !important;
        }
}

#dropdown-item-button{
    div .dropdown-menu .show{
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate(0px, 30px);
    height: 250px !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    }
}

.rowView{
    font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
    display: flex;
    width: 100%;
    min-height: 70px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* padding-left: 20px;
    padding-right: 20px; */

    .drop {
        height: 250px;
        overflow: hidden;
        overflow-y: scroll;
    }
}

.viewItems{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    width: 100%;
    background-color: #F8F8F8;
    margin: 14px 0;
    border: 1px solid #EAEAEA;
}
.bolas{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 7px;
    background-color: #A50034;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: auto;
    span{
        font-family: 'LGSmartGlobalBold', Arial, Helvetica, sans-serif;
        color: white;
        font-size: 24px;
    }
}

.columns{
    float: left;
    display: flex;
    /* width: 100%; */
    /* justify-content: space-around; */
    height: 120px;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    label{
        font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: #333333;
        display: flex;
    }
    input{
        margin-top: 25px;
        padding: 2px;
        height: 40px;
        text-align: right;
    }
}

.columns2{
    display: flex;
    /* width: 25%; */
   /*  float: left; */
    height: auto;
    padding-left: 4px;
    padding-right: 4px;
   /*  padding-left: 5px;
    padding-right: 5px; */
    label{
        font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: #333333;
    }
    
    input{
       /* margin-top: -4px; */
        width: 80px;
        padding-left: 10px;
       /*  height: 40px; */
        margin-right: 5px;
        position: relative;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}

.span_unity{
    position: relative !important;
    left: -28px !important;
}

.columns3{
    display: flex;
    width: auto;
   /*  float: left; */
    /* height: auto; */
   /*  padding-left: 5px;
    padding-right: 5px; */
    label{
        font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: #333333;
        position: relative;
    }
    
    input{
        position: relative;
        margin-left: 5px;
        margin-top: -27px;
      /*  margin-top: 10px; */
        width: 70px;
        padding: 2px;
        /* height: 40px; */
        margin-right: 5px;
        /* text-align: right; */
    }

    .margin_text_input_area{
        margin-top: -25px;
    }
}

.columnsRadio{
    font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    /* margin-right: 25px; */
    /* width: 25%; */
    /* float: left;*/
    height: 50px;
   /*  padding-left: 5px;
    padding-right: 5px; */
    label{
        font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
        font-size: 18px;
        color: #333333;
    }
    
    input{
        display: flex;
        align-items: center;
    }

    .margin_text_input{
        margin-left: 5px;
        /* margin-top: 25px; */
    }
}

.mt{
    margin-top: 10px;
}

input[type=radio] {
    border: 1px solid #707070;
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
  }
  
  input[type=radio]:checked {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiBmb2N1c2FibGU9ImZhbHNlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCSB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjYwMHB4IiBoZWlnaHQ9IjQ0Ny40MjdweCIgdmlld0JveD0iMzQwIDc2LjI4NyA2MDAgNDQ3LjQyNyINCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAzNDAgNzYuMjg3IDYwMCA0NDcuNDI3IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNBNTAwMzQiIGQ9Ik01NDMuNzg3LDUxNC45MjdsLTE5NS0xOTVjLTExLjcxNS0xMS43MTYtMTEuNzE1LTMwLjcxLDAtNDIuNDI3bDQyLjQyNS00Mi40MjcNCgljMTEuNzE1LTExLjcxNiwzMC43MTItMTEuNzE2LDQyLjQyNywwTDU2NSwzNjYuNDM0bDI4MS4zNjEtMjgxLjM2YzExLjcxNi0xMS43MTUsMzAuNzExLTExLjcxNSw0Mi40MjcsMGw0Mi40MjYsNDIuNDI3DQoJYzExLjcxNSwxMS43MTUsMTEuNzE1LDMwLjcxLDAsNDIuNDI3TDU4Ni4yMTMsNTE0LjkyOEM1NzQuNDk3LDUyNi42NDMsNTU1LjUwMiw1MjYuNjQzLDU0My43ODcsNTE0LjkyN3oiLz4NCjwvc3ZnPg0K) no-repeat center center;
    background-size: 10px 10px;
  }
  
  input[type=radio]:focus {
    outline-color: transparent;
  }

  input[type=checkbox] {
    border: 1px solid #707070;
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
  }
  
  input[type=checkbox]:checked {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiBmb2N1c2FibGU9ImZhbHNlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCSB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjYwMHB4IiBoZWlnaHQ9IjQ0Ny40MjdweCIgdmlld0JveD0iMzQwIDc2LjI4NyA2MDAgNDQ3LjQyNyINCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAzNDAgNzYuMjg3IDYwMCA0NDcuNDI3IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNBNTAwMzQiIGQ9Ik01NDMuNzg3LDUxNC45MjdsLTE5NS0xOTVjLTExLjcxNS0xMS43MTYtMTEuNzE1LTMwLjcxLDAtNDIuNDI3bDQyLjQyNS00Mi40MjcNCgljMTEuNzE1LTExLjcxNiwzMC43MTItMTEuNzE2LDQyLjQyNywwTDU2NSwzNjYuNDM0bDI4MS4zNjEtMjgxLjM2YzExLjcxNi0xMS43MTUsMzAuNzExLTExLjcxNSw0Mi40MjcsMGw0Mi40MjYsNDIuNDI3DQoJYzExLjcxNSwxMS43MTUsMTEuNzE1LDMwLjcxLDAsNDIuNDI3TDU4Ni4yMTMsNTE0LjkyOEM1NzQuNDk3LDUyNi42NDMsNTU1LjUwMiw1MjYuNjQzLDU0My43ODcsNTE0LjkyN3oiLz4NCjwvc3ZnPg0K) no-repeat center center;
    background-size: 10px 10px;
  }
  
  input[type=checkbox]:focus {
    outline-color: transparent;
  }

.buttonss{
    font-family: 'LGSmartLighSemilBold', Arial, Helvetica, sans-serif;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    padding: 15px 30px;
    background-color: #A50034;
    border-radius: 4px;
    margin: 20px 0;
    span{
        color: white;
        font-size: 13.5px;
        font-weight: 400;

    }
}

.evap_field{
    width: 100%;
    max-width: 250px;
    margin-top: 25px;/* 
    background-color: #000000; */
    select{
        height: 40px;
        padding-right: 50px;
        font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
        font-size: 16px;
        background: transparent;
        background-color: transparent;
    }

    /* .option:hover{
        background-color: #A50034 !important;
        color: #fff !important;
        padding-left: 10px !important;
        transition: all .2s ease-in-out !important;
    } */

    button{
        /* width: fit-content; */
        display: flex;
        justify-content: space-between;
        overflow: hidden;
    }

    button, .show>.btn-primary.dropdown-toggle, .show>.btn-primary.dropdown-toggle:focus, .btn-primary.focus, .btn-primary:focus{
        background-color: #fff !important;
        color: #000000 !important;
        border-color: #707070 !important;
        border-radius: 2px !important;
        height: 28px !important;
        display: flex !important;
        align-items: center !important;
        box-shadow: unset !important;
    }

    button:hover{
        background-color: #A50034 !important;
        color: #fff !important;
        border-color: #A50034 !important;
        border-radius: 2px !important;
        height: 28px !important;
        display: flex !important;
        align-items: center !important;
        box-shadow: unset !important;
    }

    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: unset !important;
    }

    .animation:hover{
        padding-left: 5px;
        transition: all .2s ease-in-out;
        /* width: fit-content; */
    }
} 
.container_modal_iframe{
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top:0;
    left:0;
    z-index: 10000;
    background: rgba(255, 255, 255, 1);
    .btn_close_iframe_VA{
        margin: .625rem;
        position: absolute;
        top:0;
        right: 0;
        font-size: 1.5rem;
    }
    iframe{
    background: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    border:none;
    padding: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .container{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        
    }

    .rows{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .columns{
        text-align: center;
        justify-content: center;
    }

    .columns2{
        text-align: center;
        /* justify-content: center; */
        margin: auto;
    }

    label{
        margin: auto;
    }

    .textSimulator{
        text-align: center;
    }
    .text{
        text-align: center;
    }

    .rowView{
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .viewItems{
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .columns2{
        justify-content: center;
    }

    .columnsRadio{        
        justify-content: center;

        .margin_text_input{
            margin-top: 0;
        }
    }

    .evap_field{
        margin: auto;
        display: flex;
        justify-content: center;
        width: 100vw;

        select{
        width: 100%;
        }

        button .dropdown{
            margin-top: 20px;
        }

        button {
            width: 80vw;
            display: flex;
            justify-content: space-between;
        }

        .dropdown-item{
            width: 80vw;
            min-height: 300px;
            overflow: hidden;
            overflow-y: scroll;
            word-wrap: break-word;
        }
    }
    
  }


  .mviewrro{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-top: 8px;
      width: 100%;
      height: 35px;
      
      .viewcontntcwc{
        display: flex;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width:60%;
        min-width: 300px;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        border: solid;
        border-width: 1px;
        border-color: #707070;
        border-radius: 3px;
    }
    .btn_open_iframe_VA{
        border-radius: 3px;
        background:#707070 ;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width:60%;
        min-width: 300px;
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
        border: solid;
        border-width: 1px;
        border-color: #707070;
        color:#fff;
        text-transform: uppercase;
        font-size: .77rem;
      }
  }

  .modalView{
      display: flex;
      flex-direction: column;
      align-items: center;
      
      position: fixed;
      top: 0px;
      padding: 20px;
      left: 0px;
      min-width: 100vw;
      max-width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,0.8);
      z-index: 99999;
      overflow:hidden;
      overflow-y:auto;
  }

  .rowbuttons{
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      height: 45px;

  }


  .buttinss{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 10px;
    height: 45px;
    background-color: #A50034;
    border: solid;
    border-width: 0px;
    border-radius: 3px;
    span{
        color:white;
        font-size: 16px;
    }
  }

  .carrseallView{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    min-height: 250px;
    background-color: white;

  }


  .carooss{
      overflow: auto !important;
  }
  .divConnt{
    display: flex;
    min-width: 240px;
    background-size: cover;
    min-height: 240px;
    // padding: 10px;
    background-color: white;
}

.divConntss{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #707070;
}

.textt{
    word-break: break-all;
}

.desktopViewModal{
    display: flex;
    flex-direction: column;
    width: 60vw;
}

.hoverModal{
    z-index: 99999;
    display: flex;
    min-width: 100%;
    flex-direction: column;
    min-height: 100%;
    justify-content: flex-end;
    cursor: pointer;
    transition: 0.5s;
    align-items: center;

    .backViewie{
        display: none;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 60%;
        background-color: rgba(0,0,0,0.8);
    }
    .buttonsnsVi{
        z-index: 99999999999;
        display: none;
        flex-direction: row;
        width: 100%;
        height: 15%;
        background:linear-gradient(#00c1fc, #005eee);
        align-items: center;
        justify-content: center;
        span{
            color: white;
            font-size: 14px;
        }
    }
    .nameModalHover{
        display: none;
        font-size: 16px;
        color: white;
        text-align: center;
    }
    .capacityModalHover{
        display: none;
        font-size: 15px;
        color: white;
        text-align: center;
    }
    .btuModalHover{
        display: none; 
        font-size: 16px;
        color: white;
        text-align: center;
        font-weight: bold;
    }
}



@media only screen and (min-width: 800px) {
    .hoverModal:hover {       
        .backViewie{
            display: flex;

            .nameModalHover{
                display: flex;
            }

            .capacityModalHover{
                display: flex;

            }
            .btuModalHover{
                display: flex; 

            }
        }
        .buttonsnsVi{
            display: flex;
            span{
                display: flex;   
            }
        }
    }
}