@import "../../css/fonts/fonts.css";

.footer{
    font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    background-color: #333333;
}

.span_footer{
    font-size: 15px;
    color: #989898;
    margin: 0;
}

@media screen and (max-width: 767px) {
    .span_footer{
        text-align: center;
    }
  }


  .saibamais{
    font-family: 'LGSmartGlobalRegular', Arial, Helvetica, sans-serif;
    color: #494239;
    font-size: 16px;
    text-align: right;
    margin: 0px;

    a{
        color: #494239 !important;
        text-decoration: unset !important;
        background-color: unset !important;
    }

    a:hover{
        color: #494239 !important;
        text-decoration: unset !important;
        background-color: unset !important;
    }
}
