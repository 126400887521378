@import "../../css/fonts/fonts.css";

.bg_Partners{
    background-color: #F8F8F8;
    padding: 30px 0;
}

.PrtnersTitle{
    font-family: 'LGSmartLightSemibold', Arial, Helvetica, sans-serif;
    color: #000;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.text{
    font-family: 'LGSmartRegular', Arial, Helvetica, sans-serif;
    color:  #333333;
    font-size: 18px;
    font-weight: 400;
}

.sub_text{
    font-family: 'LGSmartGlobalBold', Arial, Helvetica, sans-serif;
    color:  #333333;
    font-size: 18px;
    text-align: center;
}

/* 
.padding{
    padding: 10px;
} */

.optionsView{
    display: flex;
    /* flex-direction: row; */
    width: 100%;
    /* height: 50vw; */
    margin: 25px 0px;

}
.metade{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    min-height: 550px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /* height: 100%; */
}

.buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    padding: 15px;
    font-family: 'LGSmartGlobalBold', Arial, Helvetica, sans-serif;
    background-color: #A50034;
    span{
        color: #e5e5e5;
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .optionsView{
        min-height: calc(100% * 2);
        flex-direction: column;
    }
    .metade{
        width: 100%;
        height: 50%;
    }
}
