@import "../../css/fonts/fonts.css";

.title_result{
    font-family: 'LGSmartLightSemiBold', Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: #A50034;
}

.title_ambience{
    font-family: 'LGSmartLightSemiBold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #333333;
}

.container_info{
    display: flex;
    margin-top: 25px;
    margin-right: 25px;
    font-family: 'LgSmartRegular', Arial, Helvetica, sans-serif;
}

.img_bg{
    width: 100%;
    max-width: 195px;
    min-height: 195px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.title_product{
    font-family: 'LgSmartRegular', Arial, Helvetica, sans-serif;
    font-size: 18px;
    word-wrap: break-word;
    margin-bottom: 10px;
}

.info_product{
    width: 100%;
    max-width: 500px;
    position: relative;
}

@media screen and (max-width: 767px) {
   p{
       text-align: center;
   }

   .title_result{
        text-align: center;
    }

    .title_ambience{
        text-align: center;
    }
}