@import "fonts/fonts.css";
  
/* html{
    background-color:white;
} */

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
    
.icone{
    font-size: 22px;
    width: 30px;
    height: 30px;
    color: white;
}
.App{
    display:flex;
    width: 100vw;
    min-height: 100vh;
    max-height: auto;

    /* background-color:white; */
    box-sizing: border-box;
    justify-items: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  
}

@media screen and (max-width: 767px) {
    h1{
        text-align: center;
    }

    h2{
        text-align: center;
    }
  }